import { createContext, useContext, useEffect, useState } from "react";
import { getFirestore, Firestore, doc, collection, addDoc, getDoc, query, onSnapshot, updateDoc, deleteDoc, orderBy, where, getDocs, limit, startAfter, endAt } from "firebase/firestore";

import { app, functions } from "../fb/config";

import { Messaging, getMessaging } from "firebase/messaging";
import { EmailAuthProvider, onAuthStateChanged, reauthenticateWithCredential, updateEmail, updatePassword, UserCredential } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import { IUser } from "../interfaces/IUser";
import { IUserNotis } from "../interfaces/IUserNotis";
import { normalizeString, sliceIntoChunks } from "../utils/functions";
import { httpsCallable } from "firebase/functions";
import moment from "moment";

export interface IFirebaseContextProps {
  user: IUser;
  permissions: boolean;
  messaging: Messaging;
  allUsers: IUser[];
  deleteNotifications: (notisToUpdate: any) => void;
  getUserData: (id: string) => Promise<IUser>;
  loadingUser: boolean;
  updateUser: (data: IUser) => Promise<void>;
  uploadImage: (file: any) => Promise<string>;
  signup: (name: string, email: string, password: string, businessId: string, local: string) => Promise<UserCredential>;
  signout: () => Promise<void>;
  updateEmailAuth: (email: string) => Promise<void>;
  login: (email: string, password: string) => Promise<UserCredential>;
  db: Firestore;
  progresspercent: number;
  sendingPasswordResetMail: (value: string) => Promise<string>;
  updateActualPassword: (password: string, actualPassword: string, email: string) => Promise<void>;
  setNotification: React.Dispatch<any>;
  notification: {
    title: string;
    body: string;
  };
  tokenMessaging: string;
  userNotis: IUserNotis;
  updateNotifications: (notisToUpdate: any) => void;
  getUsersByBusinessId: (id: string) => Promise<IUser[]>;
  getFirebaseAllUsers: () => Promise<void>;
  getFirebaseNextUsers: (id: any) => Promise<void>;
  getAllUsersByName: (nameInit: string) => Promise<void>;
  addNotiEventBmc: (event: any, type: string, businnesName?: string) => Promise<void>;
  updateAllUsers: any;
}

export const FirebaseContext = createContext<IFirebaseContextProps>(null);

export const FirebaseProvider: any = ({ children }: any) => {
  // const { user, loadingUser } = useAuth();
  const [user, setUser] = useState<IUser>();
  const [loadingUser, setLoadingUser] = useState(true);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [permissions, setPermissions] = useState(false);
  const [tokenMessaging, setTokenMessaging] = useState(null);
  const [userNotis, setUserNotis] = useState<IUserNotis>(null);
  const [notification, setNotification] = useState<{
    title: string;
    body: string;
  }>(null);

  const messaging = getMessaging();

  const db = getFirestore(app);
  const storage = getStorage(app);

  const auth = getAuth();

  const [progresspercent, setProgresspercent] = useState(0);

  useEffect(() => {
    const fetchUserData = async (userAuth) => {
      setLoadingUser(true);
  
      try {
        if (!userAuth) {
          setUser(null);
          localStorage.removeItem("token");
          return;
        }
  
        localStorage.setItem("token", userAuth.accessToken);
  
        // ✅ Intentamos obtener usuario desde localStorage
        const cachedUser = localStorage.getItem(`user_${userAuth.uid}`);
        if (cachedUser) {
          setUser(JSON.parse(cachedUser));
          return;
        }
  
        // ✅ Si no está en cache, buscamos en Firestore
        const userRef = query(collection(db, "users"), where("uid", "==", userAuth.uid));
        const userSnapshot = await getDocs(userRef);
  
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          const userDB: IUser = {
            id: userSnapshot.docs[0].id,
            uid: userAuth.uid,
            businessId: userData.businessId || null,
            local: userData.local || null,
            name: userData.name || "",
            email: userData.email || "",
            image: userData.image || "",
            firstTime: userData.firstTime ?? false,
            responsable: userData.responsable ?? false,
            acceptLegal: userData.acceptLegal ?? false,
            nameToFilter: normalizeString(userData.name || ""), // ✅ Se añade nameToFilter
            roles: {
              canteen: userData.roles?.canteen ?? false,
              maintenance: userData.roles?.maintenance ?? false,
              parcel: userData.roles?.parcel ?? false,
              gym: userData.roles?.gym ?? false,
              super: userData.roles?.super ?? false,
              cleaner: userData.roles?.cleaner ?? false,
            },
          };
  
          // ✅ Guardamos en cache para evitar llamadas repetidas
          localStorage.setItem(`user_${userAuth.uid}`, JSON.stringify(userDB));
          setUser(userDB);
        }
      } catch (error) {
        console.error("⚠️ Error obteniendo usuario:", error);
      } finally {
        setLoadingUser(false);
      }
    };
  
    // ✅ Suscribimos al estado de autenticación
    const unsubscribe = onAuthStateChanged(auth, fetchUserData);
    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
    if (user?.roles?.super || user?.roles?.parcel) {
      getFirebaseAllUsers();
    }
  }, [user]);

  const getFirebaseAllUsers = async () => {
    const first = query(collection(db, "users"), orderBy("nameToFilter", "asc"), limit(25));
    onSnapshot(first, async (docSnap) => {
      if (docSnap.docs.length > 0) {
        const users = await Promise.all(
          docSnap.docs.map((doc: any) => {
            return { id: doc.id, ...doc.data() };
          }),
        );

        setAllUsers(users);
      } else {
        setAllUsers([]);
      }
    });
  };
  const getFirebaseAllUsers2 = async () => {
    const q = query(collection(db, "users"));
    const usersDocs = await getDocs(q);

    const users: IUser[] = await Promise.all(
      usersDocs.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      }),
    );

    return users;
  };

  const deleteBMCNotis = async () => {
    const users = await getFirebaseAllUsers2();
    const sliced50 = sliceIntoChunks(users, 250);
    sliced50.map(async (usersToUpdate) => {
      await Promise.all(
        usersToUpdate.map(async (userToUpdateNoti: IUser) => {
          const q = query(collection(doc(collection(db, "users"), userToUpdateNoti.id), "notifications"), where("type", "==", "events-business"));
          const notisToDelete = await getDocs(q);
          const notisToDeleteFinal = await Promise.all(
            notisToDelete.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            }),
          );
          await deleteNotificationsToUser(notisToDeleteFinal, userToUpdateNoti);
        }),
      );
    });
  };

  const addNotiEventBmc = async (eventData, type, name) => {
    try {
      const users = await getFirebaseAllUsers2();

      await Promise.all(
        users?.map(async (userToUpdateNoti) => {
          const q = collection(doc(collection(db, "users"), userToUpdateNoti.id), "notifications");
          await addDoc(q, {
            ...eventData,
            type: type,
            viewed: false,
            info: `Nuevo evento de BMC: ${eventData.title}`,
            createdAtt: new Date().getTime(),
          });
        }),
      );

      await sendMailWithMailchimp({ ...eventData, businessName: name ? name : "BMC" });

      console.log("Correo enviado con éxito desde Mailchimp.");
    } catch (error) {
      console.error("Error enviando el correo:", error);
    }
  };

  // Llama a Firebase Function para enviar el correo
  const sendMailWithMailchimp = async (eventData) => {
    const sendMailEventMailchimp = httpsCallable(functions, "sendMailEventMailchimp");

    try {
      // Enviar datos a la función de Firebase
      const result: any = await sendMailEventMailchimp({
        event: {
          title: eventData.title,
          description: eventData.description,
          description2: eventData?.description2,
          description3: eventData?.description3,
          externalUrl: eventData?.externalUrl,
          local: eventData?.local,
          phone: eventData?.phone,
          date: `${moment(eventData?.initTimestamp).format("DD/MM/YYYY")}–${moment(eventData?.finishTimestamp).format("DD/MM/YYYY")}`,
          image: eventData.image,
          businessName: eventData?.businessName,
        },
        userName: "",
        comments: "",
      });

      if (result.data.success) {
        console.log("Correo enviado con éxito:", result.data.message);
      } else {
        console.error("Error al enviar correo:", result.data);
      }
    } catch (error) {
      console.error("Error llamando a Firebase Function:", error);
    }
  };

  const getAllUsersByName = async (nameInit: string) => {
    const next = query(collection(db, "users"), orderBy("nameToFilter", "asc"), startAfter(nameInit), endAt(nameInit + "\uf8ff"), limit(25));

    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const filtered = await Promise.all(
        docSnap.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        }),
      );

      setAllUsers(filtered);
    } else {
      setAllUsers([]);
    }
  };

  const getFirebaseNextUsers = async (id) => {
    const next = query(collection(db, "users"), orderBy("nameToFilter", "asc"), startAfter(id), limit(25));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const copy = allUsers.slice();
      await docSnap.forEach((doc: any) => {
        copy.push({ id: doc.id, ...doc.data() });
      });

      setAllUsers(copy);
    } else {
      setAllUsers([]);
    }
  };
  const updateAllUsers = async () => {
    const next = query(collection(db, "users"));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      return await Promise.all(
        docSnap.docs.map(async (doc, idx) => {
          const nameFormatted = normalizeString(doc.data().name);
          const data = { ...doc.data(), id: doc.id, nameToFilter: nameFormatted };
          await updateUser(data);

          return data;
        }),
      );
    } else {
      console.log("Users not found");
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `gym-events/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // setProgresspercent(progress); // Asegúrate de que esta función esté definida y acepte un número
          console.log(`Upload is ${snapshot.state}, ${progress}% done`);
        },
        (error) => {
          console.error(error);
          reject(error); // Podrías considerar pasar el error a reject para manejarlo mejor
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              resolve(downloadURL);
            })
            .catch((error) => reject(error));
        },
      );
    });
  };
  const checkRoles = () => {
    if (user?.roles?.super || user?.roles?.canteen || user?.roles?.gym || user?.roles?.parcel || user?.roles?.maintenance) {
      setPermissions(true);
    } else setPermissions(false);
  };

  const updateUser = async (data: IUser) => {
    console.log("update", data);
    try {
      // 1. Actualiza el documento en Firestore
      await updateDoc(doc(db, "users", data.id), { ...data });
     
      
      
      const updatedDoc = await getDoc(doc(db, "users", data.id));
      if (updatedDoc.exists()) {
        setUser({ id: updatedDoc.id, ...updatedDoc.data() as IUser });
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  

  const signup = async (name: string, email: string, password: string, businessId: string, local: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const userData = {
      uid: user.uid,
      name: name,
      nameToFilter: normalizeString(name),
      email: user.email,
      businessId: businessId,
      local: local,
      roles: { canteen: false, maintenance: false, gym: false, parcel: false },
    };

    const usersRef = collection(db, "users");
    await addDoc(usersRef, userData);

    return userCredential;
  };
  const getUserData = async (id: string) => {
    const docRef = doc(db, "users", id);
    const docSnap: any = await getDoc(docRef);

    const user: IUser = { id: docSnap.id, ...docSnap.data() };
    return user;
  };

  const getUsersByBusinessId = async (id: string) => {
    try {
      const q = query(collection(db, "users"), where("businessId", "==", id));
      const docSnap: any = await getDocs(q);
      if (docSnap.docs.length > 0) {
        const businessUsers: IUser[] = await Promise.all(
          docSnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          }),
        );
        return businessUsers;
      } else {
        console.log("No such document!");
      }
    } catch (error) {}
  };

  const login = async (email: string, password: string) => {
    try {
      const loging = await signInWithEmailAndPassword(auth, email, password);

      return loging;
    } catch (error) {
      return null;
    }
  };

  const sendingPasswordResetMail = async (email: string) => {
    return await sendPasswordResetEmail(auth, email)
      .then(() => {
        return "Solicitud enviada correctamente";
      })
      .catch((error) => {
        return "Error enviando la solicitud";
      });
  };

  const updateEmailAuth = async (email: string) => {
    const authUser = getAuth();
    const currentUserAuth = authUser.currentUser;
    return await updateEmail(currentUserAuth, email);
  };

  const updateActualPassword = async (password: string, actualPassword: string, email: string) => {
    const authUser = getAuth();
    const currentUser = authUser.currentUser;
    const authCred = EmailAuthProvider.credential(email, actualPassword);
  
    // Reautentica al usuario y actualiza la contraseña
    await reauthenticateWithCredential(currentUser, authCred);
    await updatePassword(currentUser, password);
  
    // Si el usuario actual tiene "firstTime" en true, se actualiza a false en Firestore
    if (user && user.firstTime) {
      await updateDoc(doc(db, "users", user.id), { firstTime: false });
      
      // Actualiza el estado local y el caché para reflejar el cambio
      const updatedUser: IUser = { ...user, firstTime: false };
      setUser(updatedUser);
      localStorage.setItem(`user_${user.uid}`, JSON.stringify(updatedUser));
    }
  };
  
  const getUserNotifications = () => {
    if (user?.id) {
      const q = query(collection(doc(collection(db, "users"), user.id), "notifications"), orderBy("createdAtt", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr: any = [];
        querySnapshot.forEach(async (doc: any) => {
          const data = { ...doc.data(), id: doc.id };
          arr.push(data);
        });
        const notisCanteen = arr.filter((doc) => doc.type === "canteen");
        const notisEvents = arr.filter((doc) => doc.type === "events-business");
        const notisEventsBmc = arr.filter((doc) => doc.type === "events-bmc");
        const notisPackages = arr.filter((doc) => doc.type === "packages");
        const notisMaintenance = arr.filter((doc) => doc.type === "tickets");
        const notisCarsharing = arr.filter((doc) => doc.type === "carsharing");
        const notisSala = arr.filter((doc) => doc.type === "sala");
        const notisActivitySharing = arr.filter((doc) => doc.type === "activitysharing");

        const notisCarsharingUnviewed = notisCarsharing.filter((doc) => doc.viewed === false);
        const notisCarsharingViewed = notisCarsharing.filter((doc) => doc.viewed === true);
        const notisSalaUnviewed = notisSala.filter((doc) => doc.viewed === false);
        const notisSalaViewed = notisSala.filter((doc) => doc.viewed === true);
        const notisActivitySharingUnviewed = notisActivitySharing.filter((doc) => doc.viewed === false);
        const notisActivitySharingViewed = notisActivitySharing.filter((doc) => doc.viewed === true);
        const notisMaintenanceUnviewed = notisMaintenance.filter((doc) => doc.viewed === false);
        const notisMaintenanceViewed = notisMaintenance.filter((doc) => doc.viewed === true);
        const notisPackagesUnviewed = notisPackages.filter((doc) => doc.viewed === false);
        const notisPackagesViewed = notisPackages.filter((doc) => doc.viewed === true);

        const notisCanteenUnviewed = notisCanteen.filter((doc) => doc.viewed === false);
        const notisCanteenViewed = notisCanteen.filter((doc) => doc.viewed === true);

        const notisEventsUnviewed = notisEvents.filter((doc) => doc.viewed === false);
        const notisEventsViewed = notisEvents.filter((doc) => doc.viewed === true);

        const notisEventsBmcUnviewed = notisEventsBmc.filter((doc) => doc.viewed === false);
        const notisEventsBmcViewed = notisEventsBmc.filter((doc) => doc.viewed === true);
        const totalUnviewed = arr.filter((doc) => doc.viewed === false);
        setUserNotis({
          canteen: {
            viewed: notisCanteenViewed,
            unviewed: notisCanteenUnviewed,
          },
          eventsBmc: {
            viewed: notisEventsBmcViewed,
            unviewed: notisEventsBmcUnviewed,
          },
          tickets: {
            viewed: notisMaintenanceViewed,
            unviewed: notisMaintenanceUnviewed,
          },
          events: { viewed: notisEventsViewed, unviewed: notisEventsUnviewed },
          packages: {
            viewed: notisPackagesViewed,
            unviewed: notisPackagesUnviewed,
          },
          carsharing: {
            viewed: notisCarsharingViewed,
            unviewed: notisCarsharingUnviewed,
          },
          activitysharing: {
            viewed: notisActivitySharingViewed,
            unviewed: notisActivitySharingUnviewed,
          },
          sala: {
            viewed: notisSalaViewed,
            unviewed: notisSalaUnviewed,
          },
          totalUnviewed: totalUnviewed.length,
        });
      });
    }
  };
  useEffect(() => {
    checkRoles();
    getUserNotifications();
  }, [user, db]);

  const signout = async () => {
    try {
      await signOut(auth);
      localStorage.setItem("token", "");
    } catch (error) {
      console.log(error);
    }
  };

  const updateNotifications = (notisToUpdate) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await updateDoc(doc(db, "users", user.id, "notifications", noti.id), {
          viewed: true,
        });
      });
  };
  const deleteNotifications = (notisToUpdate) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await deleteDoc(doc(db, "users", user.id, "notifications", noti.id));
      });
  };
  const deleteNotificationsToUser = async (notisToUpdate, userToNotis) => {
    notisToUpdate &&
      notisToUpdate?.map(async (noti) => {
        return await deleteDoc(doc(db, "users", userToNotis.id, "notifications", noti.id));
      });
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        userNotis,
        updateNotifications,
        deleteNotifications,
        messaging,
        tokenMessaging,
        permissions,
        loadingUser,
        signup,
        signout,
        login,
        db,
        sendingPasswordResetMail,
        getUserData,
        uploadImage,
        progresspercent,
        allUsers,
        updateUser,
        updateEmailAuth,
        updateActualPassword,
        setNotification,
        notification,
        getUsersByBusinessId,
        getFirebaseNextUsers,
        getAllUsersByName,
        getFirebaseAllUsers,
        addNotiEventBmc,
        updateAllUsers,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useAuthState = () => {
  const auth = useContext(FirebaseContext);
  return {
    ...auth,
    loadingUser: auth.loadingUser,
    isAuthenticated: auth.user != null,
  };
};
