import  { useContext, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { QrReader } from "react-qr-reader";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../fb/config";
import SectionHeader from "../../components/SectionHeader";
import styled from "@emotion/styled";
import ModalCusstom from "../../components/ModalCustom";
import { useNavigate } from "react-router";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../context/FirebaseContext";
import Loading from "../../components/Loading";
import { GYM, SALAS, TORNOS } from "../../utils/constants";
import { checkTypeQr } from "../../utils/functions";
import { SalasContext } from "../../context/SalasContext";

const ContainerQR = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ScannerWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
`;

const ViewFinder = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  border: 4px solid rgba(255, 0, 0, 0.8);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const QrScanner = () => {
  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { db, user } = useContext(FirebaseContext);
  const { checkOpenDoor } = useContext(SalasContext);
  const navigation = useNavigate();
  const openTorn = httpsCallable(functions, "openTorn");
  const scanning = useRef(false);

  const newEntry = async () => {
    let date = new Date();
    let idToday = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const docRef = doc(db, "statistics-access", idToday);
    const docSnap = await getDoc(docRef);

    const userEntry = { userId: user.id, timestamp: new Date().getTime() };

    if (docSnap.exists()) {
      let result = docSnap.data();
      if (result?.totalAccess?.some((res) => res.userId === user.id)) return;
      await updateDoc(docRef, { totalAccess: arrayUnion(userEntry) });
    } else {
      await setDoc(docRef, {
        creationDate: new Date().setHours(0, 0, 0, 0),
        totalAccess: [userEntry],
      });
    }
  };

  const checkQr = async (result) => {
    try {
      if (!result.text) return;

      setLoading(true);
      let typeQr = checkTypeQr(result.text);
      let message = "No tienes permiso para acceder.";

      if (typeQr === TORNOS || typeQr === GYM || user.roles.super || user.roles.cleaner) {
        await openTorn({ nameTorno: result.text });
        message = "Puerta abierta correctamente";
      } else if (typeQr === SALAS) {
        let resultToCheck = await checkOpenDoor(result.text);
        if (resultToCheck) {
          await openTorn({ nameTorno: result.text });
          message = `Bienvenido ${resultToCheck.userData.name}`;
        }
      }

      setOpenModal(true);
      setData(message);
      await newEntry();

      setTimeout(() => {
        setOpenModal(false);
        navigation("/");
      }, message.includes("acceder") ? 3000 : 2000);
    } catch (error) {
      setOpenModal(true);
      setData("Código erróneo. Inténtelo de nuevo");
      setTimeout(() => setOpenModal(false), 2000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SectionHeader title="ACCESO" subtitle="Escanea el código QR para acceder." showBackButton />
      <ContainerQR>
        {!openModal && !loading && (
          <ScannerWrapper>
            <QrReader
              onResult={(result, error) => {
                if (result && !scanning.current) {
                  scanning.current = true;
                  checkQr(result).finally(() => (scanning.current = false));
                } else {
                  console.log(error);
                }
              }}
              constraints={{ facingMode: "environment" }}
              containerStyle={{ width: "100%", height: "100%" }}
              videoStyle={{ width: "100%", height: "100%", objectFit:"cover" }}
            />
            {/* <ViewFinder /> */}
          </ScannerWrapper>
        )}
      </ContainerQR>
      <ModalCusstom message={data} open={openModal} />
      {loading && <Loading />}
    </Layout>
  );
};

export default QrScanner;
