import { createContext, useEffect, useState } from "react";
import { getFirestore, collection, query, onSnapshot, orderBy, deleteDoc, doc, addDoc, updateDoc, getDoc, startAfter, limit, getDocs, endAt } from "firebase/firestore";
import { app } from "../fb/config";
import { IBusiness } from "../interfaces/IBusiness";
import { IDoc } from "./MaintenanceContext";
import { normalizeString } from "../utils/functions";

export interface IBusinessProps {
  business: IBusiness[];
  allBusiness: IBusiness[];
  loading: boolean;
  deleteBusiness: any;
  createBusiness: (doc: any) => Promise<void>;
  updateBusiness: (data: IBusiness) => Promise<void>;
  deleteBusinessTickets: (ticketsToDelete: IDoc[]) => Promise<void>;
  getBusinessById: (id: string) => Promise<IBusiness>;
  getFirebaseAllBusiness: () => Promise<void>;
  getFirebaseInitialBusiness: () => Promise<void>;
  getAllBusinessByName: (nameInit: string) => Promise<void>;
  getFirebaseNextBusiness: (id: any) => Promise<void>;
  createCategory: (doc: any) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
  categories: ICategory[];
}
export interface ICategory {
  nameToShow: string;
  id: string;
}
export const BusinessContext = createContext<IBusinessProps>(null);

export const BusinessProvider: any = ({ children }: any) => {
  const [business, setBusiness] = useState<IBusiness[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [allBusiness, setAllBusiness] = useState<IBusiness[]>([]);
  const [loading, setLoading] = useState(true);

  const db = getFirestore(app);

  useEffect(() => {
    try {
      const q = query(collection(db, "business"), orderBy("name", "asc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: IBusiness[] = await Promise.all(
          querySnapshot.docs.map((doc: any) => {
            return { id: doc.id, key: doc.id, ...doc.data() };
          })
        );
        console.log(arr)
        setBusiness(arr);
      });
      getFirebaseAllBusiness();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [db]);

  useEffect(() => {
    try {
      const q = query(collection(db, "categories"), orderBy("nameToShow", "asc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: ICategory[] = await Promise.all(
          querySnapshot.docs.map((doc: any) => {
            return { id: doc.id, key: doc.id, ...doc.data() };
          })
        );

        setCategories(arr);
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [db]);

  const getFirebaseAllBusiness = async () => {
    const first = query(collection(db, "business"), orderBy("nameToFilter", "asc"));
    const docSnap: any = await getDocs(first);

    if (docSnap.docs.length > 0) {
      const allBusiness: IBusiness[] = await Promise.all(
        docSnap.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        })
      );

      setBusiness(allBusiness);
    } else {
      setBusiness([]);
    }
  };
  const getFirebaseInitialBusiness = async () => {
    const first = query(collection(db, "business"), orderBy("nameToFilter", "asc"), limit(30));
    const docSnap: any = await getDocs(first);

    if (docSnap.docs.length > 0) {
      const allBusiness: IBusiness[] = await Promise.all(
        docSnap.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        })
      );
      setBusiness(allBusiness);
    } else {
      setBusiness([]);
    }
  };

  const updateAllBusiness = async () => {
    const next = query(collection(db, "business"));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      return await Promise.all(
        docSnap.docs.map(async (doc, idx) => {
          const nameFormatted = normalizeString(doc.data().name);
          const data = { ...doc.data(), id: doc.id, nameToFilter: nameFormatted };
          await updateBusiness(data);

          return data;
        })
      );
    } else {
      console.log("Users not found");
    }
  };

  const getAllBusinessByName = async (nameInit: string) => {
    const next = query(collection(db, "business"), orderBy("nameToFilter", "asc"), startAfter(nameInit), endAt(nameInit + "\uf8ff"), limit(25));

    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const filtered = await Promise.all(
        docSnap.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        })
      );
      setBusiness(filtered);
    } else {
      setBusiness([]);
    }
  };

  const getFirebaseNextBusiness = async (id) => {
    const next = query(collection(db, "business"), orderBy("nameToFilter", "asc"), startAfter(id), limit(25));
    const docSnap: any = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const copy = business.slice();
      await docSnap.forEach((doc: any) => {
        copy.push({ id: doc.id, ...doc.data() });
      });
      setBusiness(copy);
    } else {
      setBusiness([]);
    }
  };

  const getBusinessById = async (idBusiness: string) => {
    const q = doc(db, "business", idBusiness);
    const docSnap: any = await getDoc(q);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document!");
    }
  };

  const deleteBusiness = async (businessToDelete) => {
    await deleteDoc(doc(db, "business", businessToDelete.id));
  };

  const createBusiness = async (doc) => {
    try {
      await addDoc(collection(db, "business"), {
        name: doc.name,
        nameToFilter: normalizeString(doc.name),
        nif: doc.nif,
        description: doc?.description ? doc?.description : "",
        logo: doc?.logo ? doc?.logo : "",
        locals: doc.locals,
        showInBMC:doc.showInBMC
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteCategory = async (id) => {
    await deleteDoc(doc(db, "categories", id));
  };

  const createCategory = async (nameToShow) => {
    try {
      await addDoc(collection(db, "categories"), {
        nameToShow: nameToShow,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBusinessTickets = async (ticketsToDelete: IDoc[]) => {
    ticketsToDelete.map(async (ticket) => {
      return await deleteDoc(doc(db, "tickets", ticket.id));
    });
  };
  
  
  


  const updateBusiness = async (data: IBusiness) => {
    try {
      await updateDoc(doc(db, "business", data.id), {
        ...data,
      });
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <BusinessContext.Provider
      value={{
        business,
        allBusiness,
        deleteBusiness,
        createBusiness,
        updateBusiness,
        deleteBusinessTickets,
        loading,
        getBusinessById,
        getAllBusinessByName,
        getFirebaseAllBusiness,
        getFirebaseNextBusiness,
        getFirebaseInitialBusiness,
        createCategory,
        deleteCategory,
        categories,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
