const BmcIcon = () => {
  return (
    <svg width="60" height="auto" viewBox="0 0 2307 585" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.722609 584.344L0.722654 -3.4804e-07L14.8032 0L14.8032 584.344L0.722609 584.344Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1528.46 584.344L1528.46 0.000365863L1542.54 0.000366211L1542.54 584.344L1528.46 584.344Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M711.791 584.344L711.791 0.000365863L725.872 0.000366211L725.872 584.344L711.791 584.344Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2292.33 584.344L2292.33 0.000365863L2306.42 0.000366211L2306.42 584.344L2292.33 584.344Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2292.33 584.344L2292.33 0.000365863L2306.42 0.000366211L2306.42 584.344L2292.33 584.344Z" fill="black"/>
<path d="M445.012 284.238C495.084 303.073 515 340.744 515 381.268C515 449.76 470.048 495.421 383.558 495.421H206.596V89.0353H375.023C459.237 89.0353 498.498 137.55 498.498 195.198C498.498 235.722 479.152 265.973 445.012 284.238ZM371.609 132.414H254.393V266.544H371.609C425.665 266.544 451.84 240.289 451.84 196.91C451.84 158.669 425.665 132.414 371.609 132.414ZM381.282 452.043C438.183 452.043 467.203 425.217 467.203 381.268C467.203 331.611 434.769 309.351 380.713 309.351H254.393V452.043H381.282Z" fill="black"/>
<path d="M1261.73 89.0353H1324.53V493.815H1277.72V364.195C1277.72 310.186 1277.72 236.28 1279.43 157.825C1268.58 187.956 1257.74 215.245 1243.46 249.924L1142.98 493.815H1101.88L1000.83 249.924C986.552 215.245 975.705 187.956 964.857 157.825C966.57 236.28 966.57 310.186 966.57 364.195V493.815H919.755V89.0353H982.556L1076.76 319.282C1093.31 359.078 1111.01 404.559 1123 435.827C1135.56 402.853 1154.4 352.256 1170.96 312.46L1261.73 89.0353Z" fill="black"/>
<path d="M1916.18 504.998C1808.21 504.998 1729.22 424.633 1729.22 292.97C1729.22 161.308 1808.21 80.9429 1916.18 80.9429C1995.73 80.9429 2067.9 122.55 2088.36 220.585H2040.06C2021.87 149.909 1970.73 124.83 1916.18 124.83C1834.35 124.83 1779.23 186.957 1779.23 292.97C1779.23 398.984 1834.35 461.11 1916.18 461.11C1973.57 461.11 2032.67 426.342 2042.9 348.257H2090.63C2080.4 454.271 1999.14 504.998 1916.18 504.998Z" fill="black"/>
</svg>
  );
};

export default BmcIcon;
