import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "../../context/FirebaseContext";
import Loading from "../Loading";
import React, { useMemo } from "react";

const AnonRoute = ({ children }) => {
  const { isAuthenticated, loadingUser } = useAuthState();

  // Memoizar la lógica de autenticación
  const renderContent = useMemo(() => {
    if (loadingUser) return <Loading />;
    if (isAuthenticated) return <Navigate to="/" replace />;
    return children || <Outlet />;
  }, [loadingUser, isAuthenticated]);

  return renderContent;
};

export default React.memo(AnonRoute);
