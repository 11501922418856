import {
  collection,
  onSnapshot,
  query,
  addDoc,
  orderBy,
  arrayRemove,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { doc, deleteDoc } from "firebase/firestore";

import { app, functions } from "../fb/config";
import { httpsCallable } from "firebase/functions";

export interface INetworkProps {
  carSharings: ICarSharing[];
  activitySharing: any;
  myActivitySharing: any;
  addNetworkSharing: (data: ICarSharing, collect: string) => Promise<any>;
  deleteNetworkSharing: (id: string, collect: string) => Promise<void>;
  addUserParticipiant: (
    carSharingId: string,
    userId: string,
    collect: string
  ) => Promise<void>;
  deleteUserParticipiant: (
    carSharingId: string,
    userId: string,
    collect: string
  ) => Promise<void>;
  myCarSharings: ICarSharing[];
}

export interface IDetailCarSharing {
  origin: string;
  destination: string;
  weekDays: {
    Lunes: {
      checked: boolean;
      position: number;
    };
    Martes: {
      checked: boolean;
      position: number;
    };
    Miércoles: {
      checked: boolean;
      position: number;
    };
    Jueves: {
      checked: boolean;
      position: number;
    };
    Viernes: {
      checked: boolean;
      position: number;
    };
    Sábado: {
      checked: boolean;
      position: number;
    };
    Domingo: {
      checked: boolean;
      position: number;
    };
  };
  repeatWeekly: boolean;
  hour: string;
  places: number;
  carModel: string;
  carColor: string;
  contact: string;
  comments: string;
  title: string;
}
export interface IDetailActivitySharing {
  date: number;
  contact: string;
  hour: string;
  title: string;
  location: string;
  description: string;
}

export interface ICarSharing {
  id: string;
  userId: string;
  participants: string[];
  details: IDetailCarSharing;
}
export interface IActivitySharing {
  id: string;
  userId: string;
  participants: string[];
  image: string;
  details: IDetailActivitySharing;
}

export const NetworkContext = createContext<INetworkProps>(null);

export const NetworkProvider: any = ({ children }: any) => {
  const [carSharings, setCarSharings] = useState<ICarSharing[]>([]);
  const [activitySharing, setActivitySharing] = useState<IActivitySharing[]>(
    []
  );
  const [myActivitySharing, setMyActivitySharing] = useState<
    IActivitySharing[]
  >([]);
  const [myCarSharings, setMyCarSharings] = useState<ICarSharing[]>([]);

  const { db, user } = useContext(FirebaseContext);

  const sendNotificationCarsharingNewJoin = httpsCallable(functions, "sendNotificationCarsharingNewJoin");
  const sendNotificationCarsharingNewExit = httpsCallable(functions, "sendNotificationCarsharingNewExit");
  const sendNotificationActivitySharingNewJoin = httpsCallable(functions, "sendNotificationActivitySharingNewJoin");
  const sendNotificationActivitySharingNewExit = httpsCallable(functions, "sendNotificationActivitySharingNewExit");

  useEffect(() => {
    if (user) {
      const q = query(
        collection(db, "carsharing"),
        orderBy("details.origin", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const arr: ICarSharing[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setMyCarSharings(
          arr.filter((carSharing) => carSharing.userId === user.id)
        );
        setCarSharings(arr);
      });

      const queryActivity = query(
        collection(db, "activitysharing"),
        orderBy("details.date", "desc")
      );
      onSnapshot(queryActivity, (querySnapshot) => {
        const arr: IActivitySharing[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setMyActivitySharing(
          arr.filter((activitySharing) => activitySharing.userId === user.id)
        );
        setActivitySharing(arr);
      });
    }
  }, [user, db]);

  const addNetworkSharing = async (
    data: ICarSharing,
    collect: string
  ): Promise<any> => {
    return await addDoc(collection(db, collect), {
      userId: user.id,
      details: data,
      participants: [],
    });
  };

  const deleteNetworkSharing = async (id: string, collect: string) => {
    await deleteDoc(doc(db, collect, id));
  };

  const addUserParticipiant = async (
    carSharingId: string,
    userId: string,
    collect: string
  ) => {
    try {
      await updateDoc(doc(db, collect, carSharingId), {
        participants: arrayUnion(userId),
      });
      collect === "carsharing"
        ? await sendNotificationCarsharingNewJoin({ id: carSharingId, eventUser: user }
          )
        : await sendNotificationActivitySharingNewJoin({ id: carSharingId, eventUser: user });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUserParticipiant = async (
    carSharingId: string,
    userId: string,
    collect: string
  ) => {
    try {
      await updateDoc(doc(db, collect, carSharingId), {
        participants: arrayRemove(userId),
      });
      collect === "carsharing"
        ? await sendNotificationCarsharingNewExit({ id: carSharingId, eventUser: user })
        : await sendNotificationActivitySharingNewExit({ id: carSharingId, eventUser: user });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <NetworkContext.Provider
      value={{
        carSharings,
        myCarSharings,
        activitySharing,
        myActivitySharing,
        addNetworkSharing,
        deleteNetworkSharing,
        addUserParticipiant,
        deleteUserParticipiant,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
