import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { FirebaseContext } from "../../context/FirebaseContext";
import { IActivitySharing, NetworkContext } from "../../context/NetworkContext";
import { IBusiness } from "../../interfaces/IBusiness";
import { IUser } from "../../interfaces/IUser";
import { Text } from "../../genericComponents";
import DoubleArrowsIcon from "../../icons/services/doubleArrowsIcon";
import CalendarIcon from "../../icons/services/calendarIcon";
import ContactIcon from "../../icons/services/contactIcon";
import UserIcon from "../../icons/services/userIcon";
import InputButtonWithProps from "../InputButton";
import TrashIcon from "../../icons/trashIcon";
import ModalAccept from "../ModalAccept";
import ModalCusstom from "../ModalCustom";
import moment from "moment";

interface ICardActivitySharing {
  data: IActivitySharing;
  deleteButton?: boolean;
  type?: string;
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin: 20px;
  box-shadow: 0px 3px 6px #00000029;
  padding-bottom: 10px;
`;

const ContainerLocation = styled.div`
  background-color: #5b656e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 18px;
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    margin: 0 auto;
  }
`;

const ContainerDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  svg {
    margin-right: 15px;
  }
`;
const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  svg {
    margin-right: 15px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
  color: black;
  div:first-of-type {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div:last-of-type {
    flex: 3;
  }
`;

const ImageProfile = styled.img`
  border-radius: 50%;
  height: 32px;
  width: 32px;
`;

const Participant = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.3px solid black;
  padding: 10px;
  margin: 10px 15px;
`;

const ContainerTrash = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const ContainerInscriptions = styled.div``;

const CardActivitySharing = ({ data, deleteButton, type }: ICardActivitySharing) => {
  const { getUserData, user } = useContext(FirebaseContext);
  const { getBusinessById } = useContext(BusinessContext);
  const { addUserParticipiant, deleteUserParticipiant, deleteNetworkSharing } = useContext(NetworkContext);
  const [userOwner, setUserOwner] = useState<any | IUser>();
  const [businessOwner, setBusinessOwner] = useState<any | IBusiness>();
  const [participantsCarSharing, setParticipantsCarSharing] = useState([]);
  const [showComplete, setShowComplete] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const user = await getUserData(data.userId);
        let foundBusiness = await getBusinessById(user.businessId);
        setUserOwner(user);
        setBusinessOwner(foundBusiness);
      } catch (error) {
        console.log(error);
      }
    };

    getData();

    convertParticipants();
  }, []);

  useEffect(() => {
    convertParticipants();
  }, [data]);

  const convertParticipants = async () => {
    let arrayParticipantsPromises = data.participants.map(async (participantId: string) => {
      let participant = await getUserData(participantId);
      let foundBusiness = await getBusinessById(participant.businessId);
      return {
        name: `${participant.name}`,
        businessName: foundBusiness?.name,
      };
    });
    let arrayParticipants = await Promise.all(arrayParticipantsPromises);
    setParticipantsCarSharing(arrayParticipants);
  };

  const renderParticipants = () => {
    if (participantsCarSharing.length > 0) {
      return participantsCarSharing.map((participant: any, i) => {
        return (
          <Participant key={`${i}-keyParticipant`}>
            <Text weight="700">{participant.name}.</Text>
            <Text left="5px" weight="300">
              {participant.businessName}
            </Text>
          </Participant>
        );
      });
    } else {
      return (
        <Participant>
          <Text left="5px" weight="300">
            No hay nadie inscrito todavía.
          </Text>
        </Participant>
      );
    }
  };

  const handleClick = async () => {
    if (imNoted()) {
      deleteUserParticipiant(data.id, user.id, "activitysharing");
    } else {
      addUserParticipiant(data.id, user.id, "activitysharing");
    }
  };

  const imNoted = () => {
    return data.participants.find((participant) => participant === user.id);
  };

  const closeModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleDelete = async () => {
    setModalConfirm(false);
    setModalStatus(true);
    setMessage("Borrando activitysharing...");
    await deleteNetworkSharing(data.id, "activitysharing");
    setMessage("ActivitySharing borrado con éxito");
    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
    }, 2000);
  };
  let today = new Date().setHours(0,0,0,0)

  return (
    <Container>
      {deleteButton && (
        <ContainerTrash onClick={() => setModalConfirm(true)}>
          <TrashIcon />
        </ContainerTrash>
      )}
      <Header>
        <div>
          <ImageProfile src={userOwner?.image} alt={userOwner?.name} />
        </div>
        <div>
          <Text color="#5B656E" size="13px" weight="300">
            Publicado por:
          </Text>
          <Text size="13px" weight="700">
            {userOwner?.name}
          </Text>
          <Text size="13px" weight="300">
            {businessOwner?.name}. Local {userOwner?.local}
          </Text>
        </div>
      </Header>
      <ContainerDetails>
        <div>
          <Text width="100%" align="center" weight="700">
            {data?.details?.title}
          </Text>
        </div>
        <div>
          <DoubleArrowsIcon />
        </div>
      </ContainerDetails>
      <ContainerDetails>
        <ContainerText>
          <CalendarIcon />
          {data && (
            <Text size="13px" weight="300">
              Fecha: {moment(data.details.date).format("DD/MM/YYYY")}
            </Text>
          )}
        </ContainerText>
        <ContainerText>
          <CalendarIcon />
          {data && (
            <Text size="13px" weight="300">
              Hora: {data.details.hour}
            </Text>
          )}
        </ContainerText>
        <ContainerText>
          <UserIcon />
          {data && (
            <Text size="13px" weight="300">
              {data.participants.length} inscritos
            </Text>
          )}
        </ContainerText>
        <ContainerButton>
          {!deleteButton && (
            <InputButtonWithProps
              type="button"
              text={data.details.date <= today ? 'Caducada' : imNoted() ? "Desapuntarse" : "Apuntarse"}
              border={imNoted() ? "1px solid black" : "1px solid white"}
              textColor={imNoted() ? "black" : " white"}
              color={imNoted() ? "white" : "black"}
              disabled={data.details.date <= today }
              onClick={handleClick}
            />
          )}
        </ContainerButton>
        {showComplete && (
          <>
            <ContainerText>
              <ContactIcon />
              {data && (
                <Text size="13px" weight="300">
                  {data.details.contact}
                </Text>
              )}
            </ContainerText>
            <Text left="10px" top="10px" bottom="10px" color="#5B656E" size="13px" weight="300">
              Descripción:
            </Text>
            <Text left="10px" top="10px" bottom="10px" size="13px" weight="300">
              {data.details.description ? data.details.description : "No hay comentarios."}
            </Text>
            <Text left="10px" top="10px" color="#5B656E" size="13px" weight="300">
              Inscripciones:
            </Text>
            <ContainerInscriptions>{renderParticipants()}</ContainerInscriptions>{" "}
          </>
        )}
      </ContainerDetails>
      {/* {showComplete && (
        <InputButtonWithProps
          type="button"
          text={imNoted() ? "Desapuntarse" : "Apuntarse"}
          border={imNoted() ? "1px solid black" : "1px solid white"}
          textColor={imNoted() ? "black" : " white"}
          color={imNoted() ? "white" : "black"}
          onClick={handleClick}
          disabled={
            data.details.places === data.participants.length && !imNoted
          }
        />
      )} */}
      <Text decoration="underline" top="5px" bottom="5px" onClick={() => setShowComplete(!showComplete)}>
        {!showComplete ? "Ver detalles" : "Ocultar detalles"}
      </Text>
      <ModalAccept text={`¿Seguro que quieres eliminar tu Carsharing?`} close={closeModalConfirm} clearFunct={() => setModalConfirm(false)} open={modalConfirm} resetFunct={handleDelete} />
      <ModalCusstom message={message} open={modalStatus} />
    </Container>
  );
};

export default CardActivitySharing;
