import { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import FloatButton from "../../components/FloatButton";
import { Box } from "@mui/system";
import { ListItem } from "@mui/material";
import { FirebaseContext } from "../../context/FirebaseContext";
import ModalCusstom from "../../components/ModalCustom";
import { Text } from "../../genericComponents";
import CircleIcon from "../../components/CircleIcon";
import ModalAccept from "../../components/ModalAccept";
import { functions } from "../../fb/config";
import { httpsCallable } from "firebase/functions";

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`;
const Container = styled.div`
  padding-bottom: 150px;
  position: relative;
`;
const BoxCustom = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ListItemCustom = styled(ListItem)`
  padding: 30px;
  justify-content: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  background-color: #f6f6f6;
  border-bottom: 0.5px solid #000;
  font-weight: 300;
  width: 100%;
`;
const List = styled.div`
  width: 100%;
`;
const Searchbar = styled.input`
  padding: 5px 0;
  width: 90%;
`;

const ContainerSearchbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: Center;
  width: 90%;
  margin: 10px auto;
`;
const DeleteIcon = styled(CircleIcon)`
  margin-left: 10px;
`;
export const MoreBtn = styled.a`
  margin-top: 30px;
  font-weight: 200;
  color: #5f5f5f;
  font-size: 20px;
  text-decoration: underline;
  border-color: #5f5f5f;
`;
const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
  justify-content: space-between;
`;

const UsersPanel = () => {
  const navigation = useNavigate();
  const { allUsers, getFirebaseNextUsers, getAllUsersByName, getFirebaseAllUsers } = useContext(FirebaseContext);

  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [completeData, setCompleteData] = useState(false);

  const [selectedDoc, setSelectedDoc] = useState(null);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(allUsers);
  }, [allUsers]);

  useEffect(() => {
    getFirebaseAllUsers();
  }, []);

  const filterBusiness = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setValue(e.target.value);
    const copyStr = e.target.value;
    if (e.target.value) {
      setCompleteData(true);
      await getAllUsersByName(copyStr.toUpperCase());
    } else {
      setCompleteData(false);
      await getFirebaseAllUsers();
    }
  };

  const nextData = async () => {
    const allBusinessCopy = filteredData.slice();
    const lastVisible = allBusinessCopy[allBusinessCopy.length - 1];

    await getFirebaseNextUsers(lastVisible.name);

    if (!allUsers.length) {
      setCompleteData(true);
    }
  };


  const deleteUserFunc = httpsCallable(functions, "deleteUser");
  const deleteUser = async (uid: string, id: string) => {
    setModalConfirm(false);
    setModalStatus(true);
    setMessage("Borrando usuario...");

    await deleteUserFunc({
      uid: uid,
      id: id,
    });
    setMessage("Usuario borrado con éxito");
    setTimeout(() => {
      setMessage("");
      setModalStatus(false);
      setSelectedDoc(null);
      getFirebaseAllUsers();
    }, 2000);
  };

  const closeModalConfirm = () => {
    setModalConfirm(false);
  };

  return (
    <Layout>
      <Container>
        <SectionHeader src="/profile.jpeg" title="USUARIOS" subtitle="Gestiona usuarios" showBackButton />
        <ContainerSearchbar>
          <Searchbar placeholder="Buscar..." type="text" name="client" value={value} onChange={(e) => filterBusiness(e)} />
        </ContainerSearchbar>
        <ItemsContainer>
          <BoxCustom>
            <List>
              {filteredData.map((doc, idx) => {
                return (
                  <ListItemCustom key={doc?.id + idx}>
                    <Text width="80%">{doc?.name}</Text>
                    <ContainerIcons>
                      <CircleIcon
                        funcClick={() => {
                          navigation("/edit-user", { state: { id: doc?.id } });
                        }}
                        color="#000000"
                        src="/pencil.png"
                      />
                      <DeleteIcon
                        color="#BF2A2A"
                        src="/bin.png"
                        funcClick={() => {
                          setModalConfirm(true);
                          setSelectedDoc(doc);
                        }}
                      />
                    </ContainerIcons>
                  </ListItemCustom>
                );
              })}
              {filteredData.length === 0 && (
                <Text align="center" weight="200" left="auto" right="auto" width="90%">
                  Ningún usuario encontrado...
                </Text>
              )}
            </List>
            {/* <PaginationCusstom count={count} size="medium" page={page} variant="outlined" shape="rounded" onChange={handleChange} /> */}
            {!completeData && (
              <MoreBtn
                onClick={() => {
                  nextData();
                }}
              >
                Cargar más...
              </MoreBtn>
            )}
          </BoxCustom>
          <FloatButton onClick={() => navigation("/signup")} />
        </ItemsContainer>
        <ModalAccept
          text={`¿Seguro que quieres eliminar a ${selectedDoc?.name}?`}
          close={closeModalConfirm}
          clearFunct={() => setSelectedDoc(selectedDoc)}
          open={modalConfirm}
          resetFunct={() => {
            deleteUser(selectedDoc?.uid, selectedDoc?.id);
          }}
        />
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default UsersPanel;
