import { collection, onSnapshot, query, addDoc,  where, updateDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { doc, deleteDoc } from "firebase/firestore";
import { BusinessContext } from "./BusinessContext";
import { IBusiness } from "../interfaces/IBusiness";

export interface IEventsProps {
  eventsBmc: IEventsBmc[];
  eventsWebBmc: IEventsBmc[];
  addEventBmc: (data: IEventsBmc) => Promise<void>;
  deleteEventBmc: (id: string) => Promise<void>;
  addEventWebBmc: (data: IEventsBmc) => Promise<void>;
  deleteEventWebBmc: (id: string) => Promise<void>;
  events: IEvents[];
  addEvent: (data: IEventsBmc) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  loading: boolean;
  updateTEventBMC: (item: IEventsBmc) => Promise<void>;
  updateTEventBusiness: (item: IEvents) => Promise<void>;
}
export type IEventsBmc = {
  id?: string;
  businessId?: string;
  title: string;
  description: string;
  description2?: string;
  description3?: string;
  initTimestamp: number;
  finishTimestamp: number;
  hour?: string;
  allDay?: boolean;
  image: any;
  sliderVisible?: boolean;
  test?: boolean;
  externalUrl?:string
};
export type IEvents = {
  id?: string;
  businessId?: string;
  title: string;
  description: string;
  initTimestamp: number;
  finishTimestamp: number;
  hour?: string;
  allDay?: boolean;
  isOffer?: boolean;
  image: any;
  businessData: IBusiness;
  developer?:boolean;
  externalUrl?:string;
  phone?:string;
  local?:string;
};

export const EventsContext = createContext<IEventsProps>(null);

export const EventsProvider: any = ({ children }: any) => {
  const [eventsBmc, setEventsBmc] = useState([]);
  const [eventsWebBmc, setEventsWebBmc] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { business, getBusinessById } = useContext(BusinessContext);
  var yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());

  const now = new Date(yesterday).setHours(23, 59, 0, 0);

  const { db } = useContext(FirebaseContext);

  const addEventBmc = async (data: IEventsBmc) => {
    try {
      const res = await addDoc(collection(db, "events-bmc"), {
        initTimestamp: data.initTimestamp,
        finishTimestamp: data.finishTimestamp,
        allDay: data.allDay,
        image: data.image,
        title: data.title,
        description: data.description,
        businessId: data.businessId,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addEventWebBmc = async (data: IEventsBmc) => {
    try {
      await addDoc(collection(db, "events-bmc-web"), {
        initTimestamp: data.initTimestamp,
        finishTimestamp: data.finishTimestamp,
        allDay: data.allDay,
        image: data.image,
        title: data.title,
        description: data.description,
        businessId: data.businessId,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addEvent = async (data: IEvents) => {
    try {
      await addDoc(collection(db, "events-business"), {
        initTimestamp: data.initTimestamp,
        finishTimestamp: data.finishTimestamp,
        allDay: data.allDay,
        image: data.image,
        title: data.title,
        description: data.description,
        businessId: data.businessId,
        isOffer: data?.isOffer,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEventBmc = async (id: string) => {
    await deleteDoc(doc(db, "events-bmc", id));
  };
  const deleteEventWebBmc = async (id: string) => {
    await deleteDoc(doc(db, "events-bmc-web", id));
  };
  const deleteEvent = async (id: string) => {
    await deleteDoc(doc(db, "events-business", id));
  };

  useEffect(() => {
    const q = query(
      collection(db, "events-bmc"),
      // orderBy("initTimestamp", "desc"),
      where("finishTimestamp", ">=", now)
    );
    try {
      onSnapshot(q, (querySnapshot) => {
        const arr: IEventsBmc[] = [];
        querySnapshot.forEach((doc: any) => {
          const data = { id: doc.id, ...doc.data() };
          arr.push(data);
        });
        setEventsBmc(arr);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [db]);

  useEffect(() => {
    if (business) {
      // const startOfDayTimestamp = moment().add(1, 'days').startOf('day').valueOf();
      const q = query(
        collection(db, "events-business"),
        // orderBy("initTimestamp", "desc"),
        where("finishTimestamp", ">=", now)
      );
      try {
        onSnapshot(q, async (querySnapshot) => {
          const arr: IEvents[] = await Promise.all(
            querySnapshot.docs.map(async (doc: any) => {
              const data: IEventsBmc = { id: doc.id, ...doc.data() };
              const dataBusiness = await getBusinessById(data.businessId);

              return { ...data, businessData: dataBusiness };
            })
          );

          setEvents(arr);
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [db, business]);

  useEffect(() => {
    if (business) {
      const q = query(
        collection(db, "events-bmc-web"),
        // orderBy("initTimestamp", "desc"),
        where("finishTimestamp", ">=", now)
      );
      try {
        onSnapshot(q, async (querySnapshot) => {
          const arr: IEvents[] = await Promise.all(
            querySnapshot.docs.map(async (doc: any) => {
              const data: IEventsBmc = { id: doc.id, ...doc.data() };
              const dataBusiness = await getBusinessById(data.businessId);

              return { ...data, businessData: dataBusiness };
            })
          );

          setEventsWebBmc(arr);
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [db, business]);

  const updateTEventBMC = async (item: IEventsBmc) => {
    try {
      await updateDoc(doc(db, "events-bmc", item.id), item);
    } catch (error) {
      console.log(error);
    }
  };
  const updateTEventBusiness = async (item: IEvents) => {
    try {
      await updateDoc(doc(db, "events-business", item.id), item);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EventsContext.Provider
      value={{
        eventsBmc,
        addEventBmc,
        loading,
        deleteEventBmc,
        events,
        addEvent,
        deleteEvent,
        addEventWebBmc,
        deleteEventWebBmc,
        eventsWebBmc,
        updateTEventBMC,
        updateTEventBusiness,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
