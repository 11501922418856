import { initializeApp, FirebaseApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

export const firebaseConfig = {
  apiKey: "AIzaSyD3a5S2L7QOXdQK4zozA9DIR-0o_4FrQ_A",
  authDomain: "bmc-app-87a78.firebaseapp.com",
  projectId: "bmc-app-87a78",
  storageBucket: "gs://bmc-app-87a78.appspot.com",
  messagingSenderId: "922502980821",
  appId: "1:922502980821:web:77c6120fa7d0148272ee5c",
  measurementId: "G-F6YNXKC5ZR",
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyANuQCZPJOOdkTi-rbs1SqjkqcIu04vwN4",
//   authDomain: "bmc-dev-57cdb.firebaseapp.com",
//   projectId: "bmc-dev-57cdb",
//   storageBucket: "bmc-dev-57cdb.firebasestorage.app",
//   messagingSenderId: "367715593271",
//   appId: "1:367715593271:web:9a33847647242ef94b63b2",
//   measurementId: "G-Q7770EENER",
// };

const app: FirebaseApp = initializeApp(firebaseConfig);

const functions = getFunctions(app);

export { app, functions };

