import { Box, Dialog, List, Modal } from "@mui/material";
import { Text } from "../../genericComponents";
import { modalStyle } from "../../utils/modalStyle";
import InputButtonWithProps from "../InputButton";
import styled from "@emotion/styled";
import InputWithLegend from "../InputWithLegend";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const ModalInscriptors = ({
  open,
  close,

  usersId,
}) => {
  const { getUserData } = useContext(FirebaseContext);
  const [inscriptors, setInscriptors] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      if (usersId?.length > 0) {
        const users = await Promise.all(
          usersId?.map(async (id) => {
            const user = await getUserData(id);
            return user;
          })
        );
        setInscriptors(users);
      }
    };
    getUsers();
  }, [usersId]);

  return (
    <Modal
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      open={open}
      onClose={close}
    >
      <Box sx={modalStyle}>
        <Text top="10px" bottom="10px" align="center" size="16px">
          USUARIOS INSCRITOS
        </Text>
        <List>
          {inscriptors.length > 0 &&
            inscriptors.map((inscriptor) => {
              return <Text key={inscriptor?.id}>-{inscriptor?.name}</Text>;
            })}
        </List>
      </Box>
    </Modal>
  );
};

export default ModalInscriptors;
