import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import CardCarSharing from "../../../components/CardCarSharing";
import FloatButton from "../../../components/FloatButton";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import SelectorElementActive from "../../../components/SelectorElementActive";
import { ICarSharing, NetworkContext } from "../../../context/NetworkContext";
import { Text } from "../../../genericComponents";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const InnerContainer = styled.form`
  /* width: 100%; */
  padding: 10px;
  background-color: #f6f6f6;
`;

const Charsharing = () => {
  const navigation = useNavigate();
  const [elementActive, setElementActive] = useState(1);
  const { carSharings, myCarSharings } = useContext(NetworkContext);

  const firstElement = {
    text: "Todos",
    ref: 1,
  };

  const secondElement = {
    text: "Mis Carsharing",
    ref: 2,
  };

  const renderCardsCarSharing = () => {
    if (carSharings.length)
      return carSharings.map((infoCarSharing: ICarSharing) => {
        return <CardCarSharing key={`${infoCarSharing.id}`} data={infoCarSharing} />;
      });
    else
      return (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          Todavía no hay viajes publicados...
        </Text>
      );
  };
  const renderMyCardsCarSharing = () => {
    if (myCarSharings.length)
      return myCarSharings.map((infoCarSharing: ICarSharing) => {
        return <CardCarSharing deleteButton={true} key={`${infoCarSharing.id}`} data={infoCarSharing} />;
      });
    else
      return (
        <Text size="13px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
          Todavía no hay viajes publicados...
        </Text>
      );
  };

  return (
    <Layout>
      <Container>
        <SectionHeader src="/car.png" title="CARSHARING" subtitle="Encuentra personas del edificio con las que compartir tu coche. Puedes publicar un anuncio o adherirte a él." showBackButton />
        <FloatButton onClick={() => navigation("/carsharing/new")} />
        <SelectorElementActive firstElement={firstElement} secondElement={secondElement} setActiveElement={setElementActive} activeElement={elementActive} />
        {elementActive === 1 && <InnerContainer>{renderCardsCarSharing()}</InnerContainer>}
        {elementActive === 2 && <InnerContainer>{renderMyCardsCarSharing()}</InnerContainer>}
      </Container>
    </Layout>
  );
};

export default Charsharing;
