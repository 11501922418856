import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import { BusinessContext } from "../../../context/BusinessContext";
import { Text } from "../../../genericComponents";
import { IBusiness } from "../../../interfaces/IBusiness";
import { BoxCustom, ListItemCustom } from "../../BusinessPanel";
import { MoreBtn } from "../../Users";

const Container = styled.div`
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Searchbar = styled.input`
  padding: 5px 0;
  min-width: 203px;
  background: #f6f6f6;
  border: none;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ContainerSearchbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
`;
const BusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 135px;
  margin: 20px auto;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px #0000006b;
`;
const LocalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 80%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: 135px;
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 135px;
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 30%;
`;
const ImageBusiness = styled.img`
  margin-left: 20px;
`;
const List = styled.div`
  width: 100%;
  background-color: #f6f6f6;
`;
const CategoryBubble = styled.div`
  padding: 5px 10px;
  margin: 5px;
  background-color: #333333; /* Azul, pero ajusta según tu diseño */
  color: white;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
`;
const CategoryBubbleWhite = styled.div`
  padding: 5px 10px;
  margin: 5px;
  background-color: white; /* Azul, pero ajusta según tu diseño */
  color: #333333;
  border-radius: 20px;
  border: 1px solid #333333;
  cursor: pointer;
  user-select: none;
`;
const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra las burbujas en su contenedor */
  gap: 10px; /* Espacio entre las burbujas para evitar que se toquen */
  margin: 20px 0; /* Espacio arriba y abajo para no pegarse a otros elementos */
`;

const Business = () => {
  const navigation = useNavigate();
  useContext(BusinessContext);

  const [value, setValue] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [completeData, setCompleteData] = useState(false);

  const { business, getAllBusinessByName, getFirebaseNextBusiness, getFirebaseAllBusiness, categories } = useContext(BusinessContext);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Renderiza las burbujas de categorías
  const renderCategoryBubbles = () => {
    return categories.map((category) => (
      <>
        {selectedCategory === category.id ? (
          <CategoryBubble key={category.id} onClick={() => toggleCategoryFilter(category.id)}>
            {category.nameToShow ? category.nameToShow : "Todo"}
          </CategoryBubble>
        ) : (
          <CategoryBubbleWhite key={category.id} onClick={() => toggleCategoryFilter(category.id)}>
            {category.nameToShow}
          </CategoryBubbleWhite>
        )}
      </>
    ));
  };

  // Función para alternar el filtro de categoría
  const toggleCategoryFilter = (categoryId) => {
    if (categoryId === selectedCategory) {
      // Si se vuelve a clicar la misma categoría, quita el filtro
      setSelectedCategory(null);
      setFilteredData(business);
    } else {
      setSelectedCategory(categoryId);
      // Filtra y actualiza los datos mostrados
      const filtered = business.filter((b) => b.category === categoryId);
      setFilteredData(filtered);
    }
  };

  const filterBusiness = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setValue(e.target.value);
    const copyStr = e.target.value;
    if (copyStr) {
      setCompleteData(true);
      await getAllBusinessByName(copyStr.toUpperCase());
    } else {
      await getFirebaseAllBusiness();
      setCompleteData(false);
    }
  };
  const getCategory = (category: string) => {
    return categories.find((doc) => doc.id === category);
  };

  useEffect(() => {
    business.length && setFilteredData(business);
  }, [business]);

  useEffect(() => {
    getFirebaseAllBusiness();
  }, []);

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/network%2Fcabecera_empresas.jpg?alt=media&token=03c56edd-bc8c-42fa-bbf9-63ab95e695df"
          title="EMPRESAS"
          subtitle="Bienvenido a la red de empresas de BMC. Conoce todas las compañías del edificio."
          showBackButton
        />
        <ContainerSearchbar>
          <Searchbar placeholder="Buscar..." type="text" name="client" value={value} onChange={(e) => filterBusiness(e)} />
        </ContainerSearchbar>

        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
          <CategoriesContainer>{renderCategoryBubbles()}</CategoriesContainer>
        </div>

        <BoxCustom>
          <List>
            {filteredData.map((doc: IBusiness, idx) => {
              return (
                <BusinessCard
                  onClick={() =>
                    navigation("/business-card", {
                      state: { id: doc.id, name: doc.name },
                    })
                  }
                  key={doc.id + idx}
                >
                  <RowContainer>
                    <LeftContainer>
                      <ImageBusiness src={doc.logo ? doc.logo : "/business-default.png"} width={70} />
                    </LeftContainer>
                    <RightContainer>
                      <Text top="auto" weight="200" size="18px" width="80%">
                        {doc.name}
                      </Text>
                      {/* {doc?.web && <a style={{textDecoration:'none',width:'80%', color:'#000', textAlign:'left', cursor:'pointer' }} rel="noreferrer" target='_blank' href={doc?.web}>
                        {doc?.web}
                      </a>} */}
                      {doc?.category && (
                        <Text weight="200" size="18px" width="80%">
                          {getCategory(doc?.category)?.nameToShow}
                        </Text>
                      )}
                      <LocalsContainer>
                        <Text style={{ display: "flex", flexDirection: "row", justifyContent: "start", fontStyle: "italic", marginRight: "5px" }} weight="200" size="14px">
                          {doc?.locals.length > 0 && doc.locals.length > 1 ? "Locales " : "Local"}{" "}
                        </Text>
                        {doc?.locals.length > 0 && doc.locals.length > 1 ? (
                          doc.locals.map((local, idx) => {
                            return (
                              <Text style={{ fontStyle: "italic" }} right="5px" weight="200" size="14px">
                                {local}
                                {doc.locals.length - 1 !== idx && ","}
                              </Text>
                            );
                          })
                        ) : (
                          <Text weight="200" size="14px" width="80%">
                            {doc.locals[0]}
                          </Text>
                        )}
                      </LocalsContainer>
                      <Text decoration="underline" weight="200" left="auto" top="auto" bottom="20px" right="20px">
                        Leer más +
                      </Text>
                    </RightContainer>
                  </RowContainer>
                </BusinessCard>
              );
            })}
            {filteredData.length === 0 && (
              <Text align="center" weight="200" left="auto" right="auto" width="90%">
                Ninguna empresa encontrada...
              </Text>
            )}
          </List>
          {/* {!completeData && (
            <MoreBtn
              onClick={() => {
                nextData();
              }}
            >
              Cargar más...
            </MoreBtn>
          )} */}
        </BoxCustom>
      </Container>
    </Layout>
  );
};

export default Business;
