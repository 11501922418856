import styled from "@emotion/styled";
import axios from "axios";
import { arrayUnion, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { QrReader } from "react-qr-reader";
import {  useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import ModalCusstom from "../../components/ModalCustom";
import SectionHeader from "../../components/SectionHeader";
import { FirebaseContext } from "../../context/FirebaseContext";

const ContainerQR = styled.div`
  padding: 20px;
`;

const TemporalAccess = () => {
  const { id } = useParams();
  const { db, user } = useContext(FirebaseContext);

  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkQr = async (result: any) => {
    try {
      if (result.text) {
        const response = await axios.post("https://us-central1-bmc-app-87a78.cloudfunctions.net/openTornInvitation", {
          // const response = await axios.post("http://localhost:5001/bmc-app-87a78/us-central1/openTornInvitation", {
          id: id,
          nameTorno: result.text,
        });
        setOpenModal(true);
        setData(`Bienvenido/a ${response.data.name}`);
        newEntry();
        deleteDoc(doc(db, "temporalAccess", id));
      }
    } catch (error) {
      setOpenModal(true);
      setData("Código erróneo. Inténtelo de nuevo");
      setTimeout(() => {
        setOpenModal(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const newEntry = async () => {
    let date = new Date();
    let idToday = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

    // Primero obtenemos la colección y el elemento con la fecha de hoy
    const docRef = doc(db, "statistics-access", idToday);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Si existe, miramos si ese usuario ha entrado ya
      let result = docSnap.data();
      if (result) {
        await updateDoc(doc(db, "statistics-access", idToday), {
          totalAccess: arrayUnion({
            userId: "",
            guest: true,
            timestamp: new Date().getTime(),
          }),
        });
      }
    } else {
      // Si no existe la fecha de hoy, lo creamos con el usuario ya añadido
      const docRef = doc(db, "statistics-access", idToday);
      setDoc(docRef, {
        creationDate: new Date().setHours(0, 0, 0, 0),
        totalAccess: [
          {
            userId: "",
            guest: true,
            timestamp: new Date().getTime(),
          },
        ],
      });
    }
  };

  return (
    <Layout anonUser>
      <SectionHeader src="/car.png" title="ACCESO AL EDIFICIO" subtitle="Escanea un torno para acceder al edificio" />
      <ContainerQR>
        {!openModal && !loading && (
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setLoading(true);
                checkQr(result);
              } else {
                console.log(error);
              }
            }}
            constraints={{ facingMode: "environment" }}
          />
        )}
      </ContainerQR>
      <ModalCusstom message={data} open={openModal} />
      {loading && <Loading />}
    </Layout>
  );
};

export default TemporalAccess;
