import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "../../context/FirebaseContext";
import Loading from "../Loading";

export const ProtectedRoute = ({ redirectPath, children }) => {
  const { isAuthenticated, loadingUser, user } = useAuthState();

  console.log(isAuthenticated, user)

  if (loadingUser) {
    return <Loading />;
  } else {
    if (!isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }
    if (user && !user.acceptLegal && location.pathname !== "/legal" && location.pathname !== "/legal/info") {
      return <Navigate to={"/legal"} replace />;
    } else if (user && user.acceptLegal && location.pathname === "/legal") {
      return <Navigate to={"/"} replace />;
    } else if (user && user.firstTime && user.acceptLegal && location.pathname !== "/change-password") {
      return <Navigate to={"/change-password"} replace />;
    }

    return children ? children : <Outlet />;
  }
};
