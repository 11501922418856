import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import ModalCusstom from "../../../components/ModalCustom";
import SelectBusiness from "../../../components/SelectBusiness";
import { BusinessContext } from "../../../context/BusinessContext";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { AuthCardSignUp, ContainerSelect, Text, Title } from "../../../genericComponents";
import { IBusiness } from "../../../interfaces/IBusiness";
import { IUser } from "../../../interfaces/IUser";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import InputCheckbox from "../../../components/InputCheckbox";
import { normalizeString } from "../../../utils/functions";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../fb/config";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const CardContainer = styled.div`
  width: 100%;
  background: #f6f6f6;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 150px;
`;
const TitleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
export const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 82px;
  width: 82px;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerSpinner = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const EditUser = () => {
  const location: any = useLocation();
  const { loadingUser, updateUser, uploadImage, getUserData } = useContext(FirebaseContext);

  const { id } = location?.state;

  const updateUserEmail = httpsCallable(functions, "updateUserEmail");

  const { getBusinessById } = useContext(BusinessContext);
  const navigation = useNavigate();
  const [userData, setUserData] = useState<IUser>({
    name: "",
    nameToFilter: "",
    email: "",
    image: "",
    id: "",
    acceptLegal: false,
    uid: "",
    businessId: "",
    local: "",
    roles: {
      super: false,
      canteen: false,
      maintenance: false,
      gym: false,
      parcel: false,
    },
  });

  const [image, setImage] = useState(null);
  const [user, setUser] = useState(null);
  const [preview, setPreview] = useState("");
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [userBusiness, setUserBusiness] = useState<IBusiness>(null);
  const [locals, setLocals] = useState([]);

  const handleChange = (e: any) => {
    setAnyChange(true);
    setUserData({ ...userData, [e.target.name]: e.target.value });
    e.preventDefault();
    switch (e.target.name) {
      case "image":
        setImage(e.target.files[0]);

        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      case "name":
        setUserData({ ...userData, [e.target.name]: e.target.value, nameToFilter: normalizeString(e.target.value) });

        break;

      default:
        setUserData({ ...userData, [e.target.name]: e.target.value });
        break;
    }
  };

  const sendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("Actualizando datos...");
    setModalStatus(true);
    if (image) {
      const url = await uploadImage(image);

      await updateUserEmail({ uid: userData.uid, email: userData.email });
      await updateUser({ ...userData, image: url as string, nameToFilter: normalizeString(userData.name) });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    } else {
      await updateUserEmail({ uid: userData.uid, email: userData.email, nameToFilter: normalizeString(userData.name) });

      await updateUser({ ...userData });
      setMessage("Datos actualizados con éxito");
      setTimeout(() => {
        setMessage("");
        setModalStatus(false);
        setAnyChange(false);
      }, 3000);
    }
  };

  const handleChangeLocal = async (e: any) => {
    setUserData({
      ...userData,

      local: e.value,
    });
    setAnyChange(true);
  };
  const getInitialData = async () => {
    const copyData = JSON.parse(JSON.stringify(user));
    const businessData = await getBusinessById(user.businessId);
    const localsData = businessData?.locals?.map((local) => {
      return { value: local, label: local };
    });
    setUserData(copyData);
    setUserBusiness(businessData);
    setLocals(localsData);
    setLoadingData(false);
  };
  const getUserToEdit = async (id: string) => {
    const data = await getUserData(id);
    setUser(data);
  };

  useEffect(() => {
    !loadingUser && user && getInitialData();
  }, [user]);

  useEffect(() => {
    id && getUserToEdit(id);
  }, [id]);

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <Text top="0" weight="100" size="24px" onClick={() => navigation(-1)}>
            ←
          </Text>
          <Title size="25px" weight="200" top="10px">
            Modificar usuario
          </Title>
        </TitleContainer>
        <CardContainer>
          <AuthCardSignUp autoComplete="off" onSubmit={(e: React.FormEvent<HTMLFormElement>) => sendRequest(e)}>
            <Title align="left" size="14px" weight="600" width="100%">
              Datos personales
            </Title>
            <Text width="100%" color="#5F5F5F" size="14px" top="10px" bottom="5px">
              Foto de perfil
            </Text>
            <ImageCircle imagePreview={preview ? preview : user?.image && user.image} onClick={() => document.getElementById("image").click()}>
              {!image || (!user?.image && <Image src="/upload.png" />)}
            </ImageCircle>
            <Invisible>
              <InputInvisible name="image" type="file" id="image" onChange={handleChange} title="Imagen de perfil" />
            </Invisible>
            <InputWithLegend legendColor="#5F5F5F" type="text" name="name" title="Nombre" width="100%" value={userData?.name} onChange={handleChange} />

            <Title top="20px" align="left" size="14px" weight="600" width="100%">
              Datos laborales
            </Title>
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" name="email" title="E-mail laboral" value={userData?.email} onChange={handleChange} />
            <InputCheckbox
              text="Responsable de la empresa"
              id="responsable"
              name="responsable"
              width="100%"
              onChange={() => {
                const copy: IUser = JSON.parse(JSON.stringify(userData));
                setAnyChange(true);
                setUserData({ ...userData, responsable: !copy.responsable });
              }}
              checked={userData.responsable}
            />
            {!loadingData ? (
              <>
                <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" name="email" title="Empresa" value={userBusiness?.name} disabled />
                <ContainerSelect>
                  <Text color="#5F5F5F" size="14px" top="10px" bottom="5px">
                    Local*
                  </Text>
                  <SelectBusiness options={locals} handleChange={handleChangeLocal} name="local" value={userData?.local} />
                </ContainerSelect>
              </>
            ) : (
              <ContainerSpinner>
                <ClipLoader color="#000" loading={true} css={override} size={30} />
              </ContainerSpinner>
            )}

            <InputButtonWithProps type="submit" disabled={!anyChange} top="20px" text="Modificar datos" />
          </AuthCardSignUp>
        </CardContainer>
        <ModalCusstom message={message} open={modalStatus} />
      </Container>
    </Layout>
  );
};

export default EditUser;
