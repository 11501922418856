import styled from "@emotion/styled";
import { useContext, useState } from "react";
import InputButtonWithProps from "../../../components/InputButton";
import InputWithLegend from "../../../components/InputWithLegend";
import Layout from "../../../components/Layout";
import SectionHeader from "../../../components/SectionHeader";
import TextAreaWithLegend from "../../../components/TextAreaWithLegend";
import { AuthCardSignUp } from "../../../genericComponents";
import { FirebaseContext } from "../../../context/FirebaseContext";
import { BusinessContext } from "../../../context/BusinessContext";
import ModalCusstom from "../../../components/ModalCustom";
import { httpsCallable } from "firebase/functions";
import { app, functions } from "../../../fb/config";

const Container = styled.div`
  padding-bottom: 150px;
  background-color: #f6f6f6;
`;
const SectionHeaderCustom = styled(SectionHeader)`
  padding-bottom: 10px;
`;

const Contact = () => {
  const { user } = useContext(FirebaseContext);
  const { getBusinessById } = useContext(BusinessContext);
  const [message, setMessage] = useState("");
  const [mailData, setMailData] = useState({ subject: "", message: "" });
  const [openModal, setOpenModal] = useState(false);
  const sendMail = httpsCallable(functions, "sendMail");
  const send = async () => {
    const filtered = await getBusinessById(user.businessId);
    setOpenModal(true);
    setMessage("Enviando mail...");
    try {
      await sendMail({
        email: user?.email,
        subject: mailData.subject,
        message: mailData.message,
        userName: user?.name,
        businessName: filtered?.name,
      });
    } catch (error) {
      setOpenModal(true);
      setMessage("Error enviando mail, prueba de nuevo más tarde.");
    } finally {
      setMessage("Enviado correctamente.");
      setTimeout(() => {
        setOpenModal(false);
        setMessage("");
        setMailData({ subject: "", message: "" });
      }, 2000);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setMailData({ ...mailData, [e.target.name]: e.target.value });
  };

  return (
    <Layout>
      <Container>
        <SectionHeaderCustom
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fdistribuidor%2Fcabecera_bmc.jpg?alt=media&token=d3731f24-2025-46eb-b513-92dc468d0aa2"
          title="Contacto"
          subtitle="BMC es mucho más que un edificio de oficinas. Descubre todo lo que podemos hacer por ti."
          showBackButton
        />
        <AuthCardSignUp autoComplete="off">
          <InputWithLegend legendColor="#5F5F5F" type="text" name="subject" title="Asunto*" width="100%" value={mailData.subject} onChange={handleChange} />

          <TextAreaWithLegend legendColor="#5F5F5F" name="message" title="Mensaje*" width="100%" value={mailData.message} onChange={handleChange} />

          <InputButtonWithProps disabled={!mailData.subject || !mailData.message} type="button" onClick={() => send()} top="20px" text="Contactar" />
        </AuthCardSignUp>
        <ModalCusstom message={message} open={openModal} />
      </Container>
    </Layout>
  );
};

export default Contact;
