import { useContext, useEffect, useState } from "react";
import InputButtonWithProps from "../../components/InputButton";
import { Text, IconAbsolute, Title, AuthCardSignUp, ContainerSelect } from "../../genericComponents";
import styled from "@emotion/styled";
import EyeIcon from "../../icons/eye-icon";
import InputWithLegend from "../../components/InputWithLegend";
import SectionHeaderLogin from "../../components/SectionHeaderLogin";
// import { FirebaseContext } from "../../context/FirebaseContext";
import { BusinessContext } from "../../context/BusinessContext";
import SelectBusiness from "../../components/SelectBusiness";
import { IBusiness } from "../../interfaces/IBusiness";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ModalCusstom from "../../components/ModalCustom";
import InputCheckbox from "../../components/InputCheckbox";
import { IUser } from "../../interfaces/IUser";
import { normalizeString } from "../../utils/functions";
import { app, functions } from "../../fb/config";
import {  httpsCallable } from "firebase/functions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export const SignupCard = styled.form`
  width: 70%;
  margin: 0 auto;
  background: #f6f6f6;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;
const LoginContainer = styled.div`
  height: 100vh;
  background: #f6f6f6;
`;

const ContainerSpinner = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Spinner = styled(ClipLoader)``;
// const InputInvisible = styled.input`
//   display: flex;
// `;
// const Invisible = styled.div`
//   display: none;
// `;
// const ImageCircle = styled.span`
//   height: 82px;
//   width: 82px;
//   background: #f6f6f6;
//   border: 1px solid black;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   cursor: pointer;
// `;

// const Image = styled.img`
//   height: 27px;
//   width: 27px;
// `;

const Signup = () => {
  const [errorMessage, setErrorMessage] = useState("");
  // const { uploadImage, signup } = useContext(FirebaseContext);
  const { loading, getBusinessById, business } = useContext(BusinessContext);

  const createUser = httpsCallable(functions, "createUser");

  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [image, setImage] = useState(null);
  const [seePass, setSeePass] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    responsable: false,
    email: "",
    password: "",
    businessId: "",
    local: "",
  });
  const [selectedBusiness, setSelectedBusiness] = useState<IBusiness>({
    name: "",
    nameToFilter: "",
    email: "",
    description: "",

    logo: "",
    id: "",
    locals: [],
    nif: "",
  });

  const [locals, setLocals] = useState([]);
  const [message, setMessage] = useState("");

  const options = business?.map((doc) => {
    return { value: doc.id, label: doc.name };
  });


  // useEffect(() => {
  //   business?.length > 0 &&
  //     !selectedBusiness &&
  //     setSelectedBusiness(business[0]);
  // }, [business]);

  useEffect(() => {
    selectedBusiness?.name && getBusinessOptions();
  }, [selectedBusiness]);

  const handleChange = async (e: any) => {
  
    setErrorMessage("");
    e.preventDefault();

    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleChangeSelect = async (e: any) => {
    setErrorMessage("");

    const finded = await getBusinessById(e.value);

    setUserData({
      ...userData,
      businessId: e.value,
      local: finded.locals[0],
    });

    setSelectedBusiness(finded);
  };
  const handleChangeLocal = async (e: any) => {
    setErrorMessage("");

    setUserData({
      ...userData,

      local: e.value,
    });
  };

  
  const signUp = async (e: any) => {
    e.preventDefault();
    setMessage("Creando usuario...");
    setIsOpenModal(true);
    // const url = await uploadImage(image);
    // if (url) {
    const userDB = await createUser({
      name: userData.name,
      nameToFilter: normalizeString(userData.name),
      email: userData.email,
      password: userData.password,
      businessId: userData.businessId,
      local: userData.local,
      responsable: userData.responsable,
    });

    if (userDB) {
      setMessage("Creado con éxito");
      setTimeout(() => {
        resetForm();
      }, 3000);
    } else {
      setMessage("");
      setErrorMessage("usuario o contraseña incorrecto");
    }
    // }
  };
  const resetForm = () => {
    setUserData({
      name: "",
      email: "",
      password: "",
      businessId: "",
      local: "",
      responsable: false,
    });
    setMessage("");
    setIsOpenModal(false);
    setSelectedBusiness({
      name: "",
      nameToFilter: "",
      email: "",
      description: "",
      logo: "",
      id: "",
      locals: [],
      nif: "",
    });
  };
  const getBusinessOptions = () => {
    const localsFiltered = selectedBusiness.locals.map((local, idx) => {
      return { value: local, label: local };
    });
    setUserData({ ...userData, local: localsFiltered[0].label });
    setLocals(localsFiltered);
  };

  const handlePass = () => {
    setSeePass(!seePass);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  return (
    <div>
      {!loading ? (
        <LoginContainer>
          <SectionHeaderLogin title="Nuevo usuario" />
          <AuthCardSignUp autoComplete="off" onSubmit={(e: any) => signUp(e)}>
            <Title align="left" size="14px" weight="600" width="100%">
              Datos personales
            </Title>
            <InputWithLegend legendColor="#5F5F5F" type="text" name="name" title="Nombre*" width="100%" value={userData?.name} onChange={handleChange} />

            <InputWithLegend
              width="100%"
              legendColor="#5F5F5F"
              type={seePass ? "text" : "password"}
              name="password"
              title="Contraseña*"
              value={userData?.password}
              onChange={handleChange}
              icon={
                <IconAbsolute onClick={() => handlePass()} color="#fff" size="auto" pointer top="8px" right="5px">
                  <EyeIcon opacity={!seePass ? "0.4" : "1"} />
                </IconAbsolute>
              }
            />

            <Title top="20px" align="left" size="14px" weight="600" width="100%">
              Datos laborales
            </Title>
            <InputWithLegend width="100%" legendColor="#5F5F5F" type="email" name="email" title="E-mail laboral*" value={userData?.email} onChange={handleChange} />
            <InputCheckbox
              text="Responsable de la empresa"
              id="responsable"
              name="responsable"
              width="100%"
              onChange={() => {
                const copy: IUser = JSON.parse(JSON.stringify(userData));
                setUserData({ ...userData, responsable: !copy.responsable });
              }}
              checked={userData.responsable}
            />

            <ContainerSelect>
              <Text color="#5F5F5F" size="14px" top="10px" bottom="5px">
                Empresa*
              </Text>
              <SelectBusiness options={options} handleChange={handleChangeSelect} name="businessId" value={userData?.businessId} />
            </ContainerSelect>
            {selectedBusiness?.name && (
              <ContainerSelect>
                <Text color="#5F5F5F" size="14px" top="10px" bottom="5px">
                  Local*
                </Text>
                <SelectBusiness options={locals} handleChange={handleChangeLocal} name="local" value={userData?.local} />
              </ContainerSelect>
            )}
            <InputButtonWithProps disabled={!userData?.name || !userData?.email || !userData?.password || !userData?.local || !userData?.businessId} type="submit" top="20px" text="Crear usuario" />
            {errorMessage && <Text color="red">{errorMessage}</Text>}
          </AuthCardSignUp>

          <ModalCusstom message={message} open={isOpenModal} />
        </LoginContainer>
      ) : (
        <LoginContainer>
          <SectionHeaderLogin title="Nuevo usuario" />
          <ContainerSpinner>
            <Spinner color="#000" loading={true} css={override} size={50} />
          </ContainerSpinner>
        </LoginContainer>
      )}
    </div>
  );
};

export default Signup;
